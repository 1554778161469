import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { Area, BusinessGroup, Client, DiscountGroup, Location, MyHttpApi, PrivilegeItem } from 'utils/api';
import { getAllAccessLevelsViaClient, getSelectedClient, getSelectedCorporation } from 'utils/corporation-util';


interface UIDiscountGroup extends DiscountGroup {
	deleted: boolean;
}

@autoinject
export class PosDiscountGroupList {
	private locations: { [key: string]: Location; } = {};

	private fieldList: FieldSpec[] = [
		{ key: "name", header: "common.name", type: "text", },
		{ key: "value", header: "common.value", type: "text", },
		{ key: "locationId", header: "app.posLocation", type: "lookup", lookupData: this.locations, lookupKey: "name", },
		{ key: "deleted", header: "common.deleted", type: "boolean", },
	];
	private readonly defaults: { [key: string]: string; } = { "deleted": " " };
	private itemList: UIDiscountGroup[] = [];
	private privilege?: PrivilegeItem;
	private clientId?: number;
	private isMaster = false;
	private clients: { [key: number]: Client; } = {};
	private businessGroups: { [key: number]: BusinessGroup; } = {};

	constructor(private readonly api: MyHttpApi, private readonly router: Router) {
	}

	async activate(params: { isMaster: string; }) {
		let { clients, businessGroups } = await getAllAccessLevelsViaClient(this.api);
		this.clients = clients;
		this.businessGroups = businessGroups;
		this.isMaster = params.isMaster === "true";

		let [clientList] = await Promise.all([
			this.api.clientList(),
		]);

		for (let c of clientList) {
			clients[c.id] = { ...c, name: c.nickname };
		}
		this.clientId = getSelectedClient();
		if (!this.isMaster) {
			this.privilege = getSelectedCorporation();
			this.fieldList = [...this.fieldList,
			{ key: "businessGroupId", header: "businessGroup.businessGroup", type: "lookup", lookupData: businessGroups, lookupKey: "name", },
			{ key: "clientId", header: "unit.unit", type: "lookup", lookupData: clients, lookupKey: "name", },
			];
		}
		await this.search();
		let [locationList] = await Promise.all([
			this.api.locationListAll(),
			this.search(),
		]);
		for (let location of locationList) {
			this.locations[location.id] = location;
		}
	}

	async search() {
		if (!this.isMaster && !this.privilege) {
			return;
		}
		const itemList = await this.api.discountGroupList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' });
		this.itemList = itemList.filter(x => !x.supersededById).map(x => ({ ...x, deleted: !!x.deleteTime }));
	}

	rowCall(key: string, row?: Area) {
		this.router.navigateToRoute(this.editUrl, { id: row?.id, clientId: this.clientId });
	}

	@computedFrom("router.currentInstruction.config.navModel.config.name")
	get editUrl() {
		let routeName = this.router?.currentInstruction?.config?.navModel?.config?.name || "";
		return routeName.replace(/-list$/, "-edit");
	}

	@computedFrom("privilege.id")
	get searchListener() {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		this.search();
		return "";
	}
}