import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { DiscountGroupRowUpdateRequest, DiscountGroupUpdateRequest, IdName, Location, MyHttpApi, PrivilegeItem, Product, ProductCategory, ProductSubCategory } from 'utils/api';
import { getPrivilege, privilegeItemFromElement, privilegeToTypeAndName, supersededText } from 'utils/corporation-util';
import { Notify } from 'utils/notify';

interface UIDiscountGroupRow {
	value: number;
	productId?: string,
	productCategoryId?: string,
	productSubCategoryId?: string,
}

export interface UIDiscountGroupSpecifyRows extends IdName {
	rows: ItemModel[];
}

interface DiscountGroupSpecifyRequestTorso {
	clientId: number;
	name?: string;
}

interface DiscountGroupSpecifyCancel extends DiscountGroupSpecifyRequestTorso {
  rows: UIDiscountGroupRow[];
}

class ItemModel{
	private product?: Product = undefined;
	private extraProductCategory?: ProductCategory;
	private extraProductSubCategory?: ProductSubCategory;
	private extraProduct?: Product = undefined;

	@computedFrom("productSubCategoryList.length", "row.productCategoryId")
	get productSubCategoryListDynamic() {
		const productSubCategory = this.productSubCategoryList.find(x => x.id == this.row.productSubCategoryId);
		if (this.row.productSubCategoryId && productSubCategory?.productCategoryId !== this.row.productCategoryId) {
			this.row.productSubCategoryId = undefined; // generated from next()
			this.extraProductSubCategory = undefined;
		}
		return this.productSubCategoryList.filter(p => !p.deleteTime && !p.supersededById && this.row.productCategoryId && p.productCategoryId === this.row.productCategoryId);
	}

	@computedFrom("productList.length", "row.productCategoryId", "row.productSubCategoryId")
	get productListDynamic() {
		return this.productList.filter(p => {
			if (p.deleteTime || p.supersededById) {
				return false;
			}
			if (this.row.productCategoryId && p.productCategoryId !== this.row.productCategoryId) {
				return false;
			}
			if (this.row.productSubCategoryId && p.productSubCategoryId !== this.row.productSubCategoryId) {
				return false;
			}
			return true;
		});
	}

	constructor(public productCategoryList: ProductCategory[], public productSubCategoryList: ProductSubCategory[], public productList: Product[], public row: DiscountGroupRowUpdateRequest) {
		if (row.productId) {
			this.product = productList.find(p => p.id === row.productId);
			if (this.product?.deleteTime) {
				this.extraProduct = this.product;
			}
		}
		if (row.productCategoryId) {
			const productCategory = productCategoryList.find(pc => pc.id === row.productCategoryId);
			if (productCategory?.deleteTime) {
				this.extraProductCategory = productCategory;
			}
		}
		if (row.productSubCategoryId) {
			const productSubCategory = productSubCategoryList.find(pc => pc.id === row.productSubCategoryId);
			if (productSubCategory?.deleteTime) {
				this.extraProductSubCategory = productSubCategory;
			}
		}
	}

	toDiscountGroupRow() {
		return {
			value: this.row.value,
			productId: this.product?.id,
			productCategoryId: this.row.productCategoryId,
			productSubCategoryId: this.row.productSubCategoryId
		}
	}
}

@autoinject
export class PosDiscountGroupEdit {
	private productCategoryList: ProductCategory[] = [];
	private productSubCategoryList: ProductSubCategory[] = [];
	private productList: Product[] = [];
	private discountGroup: DiscountGroupUpdateRequest = {
		name: "",
		value: 0,
		locationId: "",
		type: "CLIENT",
		rows: [],
		delete: false,
	};
	private typeAndName = "";
	private locationList: Location[] = [];
	private privilege?: PrivilegeItem;
	private canEdit = true;
	private canSpecify = false;
	private superseded = false;
	private specifyRows: UIDiscountGroupSpecifyRows[] = [];
	private clientLocationMap: { [key: number]: Location[]; } = {};
	private rows: ItemModel[] = [];

	// * List of clients that the actor can see within the BG
	private clientList: IdName[] = [];
	// * Used to specify, which client is currently being targeted
	private specifyRequest?: DiscountGroupSpecifyRequestTorso = undefined;
	// * In case of specify-cancel, restore previous state
	private specifyRequestForCancel?: DiscountGroupSpecifyCancel = undefined;
	// * Value from unused client list
	private specifySelectClientValue?: number;

	constructor(private readonly api: MyHttpApi, private readonly router: Router, private readonly i18n: I18N, private readonly notify: Notify) { }

	async activate(params: { id?: string; }) {
		this.privilege = getPrivilege();
		let [productCategoryList, productSubCategoryList, productList] = await Promise.all([
			this.api.productCategoryList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.productSubCategoryList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.productList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
		]);
		this.productList = productList.filter(x => !x.deleteTime && !x.supersededById);
		this.productCategoryList = productCategoryList.filter(x => !x.deleteTime && !x.supersededById);
		this.productSubCategoryList = productSubCategoryList.filter(x => !x.deleteTime && !x.supersededById);
		this.productList = productList.filter(x => !x.deleteTime && !x.supersededById);
		this.typeAndName = await privilegeToTypeAndName(this.api, this.i18n, this.privilege);

		if (params.id) {
			let [discountGroup, rowResponse] = await Promise.all([
				this.api.discountGroupById({ id: params.id }),
				this.api.discountGroupListRows({ id: params.id }),
			]);
			this.discountGroup = { ...discountGroup, delete: !!discountGroup.deleteTime, type: 'CLIENT', rows: [] };
			this.rows = rowResponse.rows.map(x => this.createRow(x));
			this.specifyRows = rowResponse.specifyRows.filter(x => x.rows.length).map(sr => {
				return {
					id: sr.id,
					name: sr.name,
					rows: sr.rows.map(x => this.createRow(x))
				}
			});
			this.privilege = privilegeItemFromElement(discountGroup);
			this.superseded = !!discountGroup.supersededById;
		}


		if (this.privilege) {
			this.canEdit = await this.api.privilegeCanEdit(this.privilege);
			if (this.privilege.id && this.privilege.type === 'CLIENT') {
				const [bgr, locationList] = await Promise.all([
					this.api.businessGroupRestrictionsByClientId({ clientId: this.privilege.id }),
					this.api.locationList({ id: this.privilege.id }),
				]);
				this.locationList = locationList.filter(x => !x.deleteTime);
				this.canEdit = bgr.clientSpecificDiscountGroups == 'ALLOW';
			}
			if (this.privilege.type == 'BUSINESS_GROUP' && this.privilege.id) {
				const [clientList, bg] = await Promise.all([
					this.api.privilegeListClientsByBusinessGroupId({ businessGroupId: this.privilege.id }),
					this.api.businessGroupById({ id: this.privilege.id })
				]);
				this.clientList = clientList;
				this.canSpecify = bg.clientSpecificDiscountGroups != 'RESTRICT';
			}
		}
	}

	@computedFrom("privilege.type")
	get clientSpecific() {
		return this.privilege?.type === 'CLIENT';
	}
	@computedFrom("disabled", "canSpecify", "superseded")
	get disabledText() {
		let text = "";
		if (this.superseded) {
			return supersededText(this.i18n, "discountGroup");
		} else if (!this.canEdit) {
			text = this.i18n.tr('businessGroupRestriction.readOnly');
			if (this.canSpecify) {
				text += ` ${this.i18n.tr("businessGroupRestriction.canSpecify")}`;
			}
		}
		return text;
	}

	@computedFrom("clientList", "specifyRows.length")
	get unspecifiedClientList() {
		return this.clientList.filter(c => !this.specifyRows.find(r => r.id === c.id));
	}

	_buildRequest(): DiscountGroupUpdateRequest {
		let discountGroup = this.discountGroup;
		return {
			...discountGroup,
			privilegeId: this.privilege?.id,
			type: this.privilege?.type || 'CLIENT',
			name: discountGroup.name || '',
			rows: this.rows.map(r => r.toDiscountGroupRow())
		};
	}

	async save(deleted: boolean) {
		let obj = this._buildRequest();
		obj.delete = deleted;
		await this.api.discountGroupUpdate(obj);
		this.router.navigateBack();
	}

	async toggleDelete() {
		await this.save(!this.discountGroup.delete);
	}

	addRow(rows: ItemModel[]) {
		rows.push(this.createRow({ value: 0 }));
	}

	/** When selecting before unseen client from select-box */
	async startSpecifyEmpty() {
		let idName = this.clientList.find(x => x.id == this.specifySelectClientValue);
		if (idName) {
			await this.startSpecify(idName);
		}
	}

	/** Open the client for modification, save the previous specify if available */
	async startSpecify(idName: IdName) {
		if (this.specifyRequest) {
			if (!(await this.saveSpecify(this.specifyRequest.clientId))) {
				return;
			}
		}
		const clientId = idName.id;
		let foundReq = this.specifyRows.find(x => x.id === clientId);
		let discountGroupRows = foundReq?.rows || [];
		const discountGroupTimesForCancelRequest = [...discountGroupRows];
		if (!discountGroupRows.length) {
			discountGroupRows.push(this.createRow({value: 0}));
		}
		let req: DiscountGroupSpecifyRequestTorso = {
			clientId,
			name: idName.name,
		};
		if (!this.specifyRows.find(x => x.id === clientId)) {
			this.specifyRows.unshift({
				id: clientId,
				name: idName.name || "",
				rows: [this.createRow({ value: 0 })],
			});
		}
		this.specifyRequest = req;
		this.specifyRequestForCancel = { ...req, rows: discountGroupTimesForCancelRequest.map(x => x.toDiscountGroupRow()) };
		this.specifySelectClientValue = undefined;
	}

	createRow(x: DiscountGroupRowUpdateRequest) {
		return new ItemModel(this.productCategoryList, this.productSubCategoryList, this.productList, x);
	}

	/** Cancel the operation, restore server data that we had while loading the page originally */
	cancelSpecify() {
		let clientId = this.specifyRequest?.clientId;
		if (clientId) {
			this.specifyRequest = undefined;
			let idx = this.specifyRows.map(x => x.id).indexOf(clientId);
			// * Check if we had previous data and if did have rows
			let cr = this.specifyRequestForCancel;
			if (cr?.rows.length) {
				let originalSpecifyRow = {
					id: cr.clientId,
					name: cr.name || "",
					rows: cr.rows.map(r => this.createRow(r)),
				};
				this.specifyRows.splice(idx, 1, originalSpecifyRow);
			} else {
				// * Item did not exists before, remove fully from list
				this.specifyRows = this.specifyRows.filter(x => x.id !== clientId);
			}
		}
		this.specifyRequestForCancel = undefined;
	}

	/** Save the specify */
	async saveSpecify(clientId: number) {
		let data = this.specifyRows.find(x => x.id == clientId);
		if (!data || !this.discountGroup.id/* || !this.validateRows(data.rows)*/) {
			return false;
		}
		await this.api.discountGroupSpecify({
			elementId: this.discountGroup.id,
			clientId: data.id,
			rows: [...data.rows.map(r => r.toDiscountGroupRow())] as DiscountGroupRowUpdateRequest[],
		});
		this.specifyRequest = undefined;
		return true;
	}

	/** Just save a set of empty rows */
	async deleteSpecify() {
		const clientId = this.specifyRequest?.clientId;
		if (this.discountGroup.id && clientId) {
			await this.api.discountGroupSpecify({
				elementId: this.discountGroup.id,
				clientId,
				rows: [],
			});
		}
		this.specifyRequest = undefined;
		this.specifyRows = this.specifyRows.filter(x => x.id !== clientId);
	}

	deleteRow(rows: ItemModel[], idx: number) {
		rows.splice(idx, 1);
	}
}